

/* HERO IMAGEN INICIAL */

.hero {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  margin: 0; /* Elimina márgenes */
  padding: 0;
}

.heroImage {
  width: 100%;
  height: 63vh;
  object-fit: cover;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
  }

  /* Diseño para tabletas */
  @media (min-width: 768px) and (max-width: 1024px) {
  height: calc(100% - 119px);
  padding: 0;
  }

}

.heroContent {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
  }
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin-bottom: 1rem;
}

.brandLogo {
  height: 40px;
  width: auto;
}

.brandHighlight {
  color: #22c55e;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1;
}

.heroTitle {
  font-size: 4rem;
  font-weight: 900; /* O extrablack si está disponible */
  color: #000;
  line-height: 1.1;
  margin: 0.5rem 0;
  font-family: 'Helvetica Now Display Extrablack', sans-serif;
  letter-spacing: -0.5px;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
}

.heroText {
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 400;
  color: #000;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Helvetica Now Display', sans-serif;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

.videoSection {
  background-color: #F2F2F2;
  padding: 4rem 0;
  width: 100%;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  gap: 4rem;
  align-items: flex-start;
}

.videoWrapper {
  position: relative;
  width: 100%;
  max-width: 100%; /* Se ajusta al ancho disponible */
  height: auto; /* Ajusta la altura automáticamente */
  border-radius: 8px;
  overflow: hidden;
}

.videoContainer {
  flex: 1;
  max-width: 100%; /* Se asegura de que el contenedor no limite la imagen */
  margin-top: 20px;
}


.videoThumbnail {
  width: 100%;
  height: auto; /* Esto asegura que la imagen mantenga su proporción original */
  object-fit: cover; /* Ajusta cómo se muestra la imagen dentro del contenedor */
  display: block;
}


.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.videoOverlay:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.playButton {
  width: 68px;
  height: 48px;
  background-color: rgba(255, 0, 0, 0.9);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.playIcon {
  width: 24px;
  height: 24px;
  color: white;
}

.contentContainer {
  flex: 1;
  max-width: 600px;
  text-align: left;
  padding-top: 0;
}

.greenTitle {
  color: #19A614;
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0 0.25rem 0;
  text-transform: uppercase;
}

.mainTitle {
  font-size: 2.5rem;
  font-weight: 900; /* O extrablack si está disponible */
  color: #000;
  line-height: 1.1;
  margin: 0 0 1rem 0;
  font-family: 'Helvetica Now Display Extrablack', sans-serif;
}

.description {
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 0.75rem;
}

.descriptionOne {
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 800;
  color: #000;
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 1.75rem;
}

.learnMoreButton {
  background-color: #ff4500;
  color: white;
  font-size: 1rem;
  padding: 0.7rem 4rem; /* Tamaño compacto */
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  text-decoration: none;
  margin-top: 2rem;
}

.learnMoreButton:hover {
  background-color: #e63e00;
  transform: scale(1.1); /* Agranda el botón al pasar el mouse */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra al pasar el mouse */
}

.learnMoreButton:active {
  transform: scale(0.95); /* Reduce ligeramente al hacer clic */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduce la sombra */
}


/* Estilos específicos para dispositivos móviles */
@media (max-width: 767px) {
  .container {
    flex-direction: column-reverse; /* Cambia el orden: texto primero, video después */
    gap: 1.5rem; /* Reduce el espacio entre los elementos */
    margin-top: -2rem;
  }

  .videoWrapper {
    max-width: 100%; /* Ocupa todo el ancho disponible */
    margin: 0 auto; /* Centra el video */
    margin-top: -20rem;
    margin-bottom: -25rem;
  }

  .contentContainer {
    text-align: center; /* Centra el texto */
    padding: 0 1rem;
  }

  .mainTitle {
    font-size: 1.5rem; /* Ajusta el tamaño del título */
  }

  .description, .descriptionOne {
    font-size: 0.9rem; /* Ajusta el tamaño del texto */
  }

  .descriptionOne {
    margin-bottom: 17rem;
  }

  .learnMoreButton {
    padding: 0.75rem 2rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    flex-direction: column-reverse; /* Cambia el orden: texto primero, video después */
    gap: 1.5rem; /* Reduce el espacio entre los elementos */
    margin-top: -2rem;
  }

  .videoWrapper {
    max-width: 80%; /* Ocupa todo el ancho disponible */
    margin: 0 auto; /* Centra el video */
    margin-top: -26rem;
    margin-bottom: -25rem;
  }

  .contentContainer {
    text-align: center; /* Centra el texto */
    padding: 0 3rem;
  }

  .mainTitle {
    font-size: 1.5rem; /* Ajusta el tamaño del título */
  }

  .description, .descriptionOne {
    font-size: 0.9rem; /* Ajusta el tamaño del texto */
  }

  .descriptionOne {
    margin-bottom: 24rem;
  }

  .learnMoreButton {
    padding: 0.75rem 2rem;
  }
}

.achievementSection {
  background-color: #F2F2F2; /* Fondo opcional */
  padding: 4rem 2rem;       /* Espaciado interno */
  text-align: center;       /* Mantiene el título centrado */
  display: flex;            /* Flexbox para estructura */
  flex-direction: column;   /* Orden en columna */
  align-items: center;      /* Centra todo horizontalmente */
  gap: 1.5rem;              /* Espacio entre el título y el texto */
}

.achievementTitle {
  font-size: 3rem;          /* Tamaño del título */
  font-weight: 900;         /* Negrita para el título */
  margin: 0;                /* Eliminamos márgenes extra */
  color: #333;              /* Color del texto */
  font-family: 'Helvetica Now Display Extrablack', sans-serif;

  @media (max-width: 1300px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 2rem;
    margin-top: -7rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 2rem;
    margin-top: -7rem;
  }
}

.achievementText {
  font-size: 1.5rem;        /* Tamaño del texto */
  line-height: 1.6;         /* Espaciado entre líneas */
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 500;
  color: #333;
  max-width: 1200px;        /* Ancho máximo del texto */
  margin: 0 auto;           /* Centraliza el texto horizontalmente */
  text-align: center;       /* Mantiene el texto alineado al centro */

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 1rem;
    margin-bottom: -2rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 1rem;
    margin-bottom: -2rem;
  }
}

.sectionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.parentEducationSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  padding: 2rem 0;
  background-color: #F2F2F2;
}

.parentEducationSectionSec {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  padding: 2rem 0;
  background-color: #F2F2F2;
}

.leftContent 
.rightContent {
  flex: none;
}

.gradientBackground {
  width: 11cm;
  height: 11cm;
  padding: 1rem;
  border-radius: 0;
  background: 
  radial-gradient(circle at 80% 80%, #F1E9F2 30%, transparent 60%),
  radial-gradient(circle at 70% 40%, #CCCED9 20%, transparent 60%),
  radial-gradient(circle at 30% 70%, #D5E5F2 25%, transparent 60%),
  radial-gradient(circle at 20% 20%, #D8F0F2 30%, transparent 70%),
  radial-gradient(circle at 50% 50%, #C5D7D9 35%, transparent 70%);
  background-color: #F1E9F2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textContent {
  color: #333;
  text-align: left;
}

.sectionTitle {
  font-size: 1.25rem;
  font-weight: 700; /* O extrablack si está disponible */
  color: #000;
  margin-bottom: 0.5rem;
  font-family: 'Helvetica Now Display Extrablack', sans-serif;
}

.sectionSubtitle {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 800;
  color: #000;
  font-family: 'Helvetica Now Display', sans-serif;
  line-height: 1;
}

.descriptionThree {
  font-size: 1rem;
  line-height: 1.4;
  color: #000;
}

.educationImage {
  width: 17cm;
  height: 11cm;
  object-fit: cover;
  border-radius: 0;
  margin-left: -0.1rem;
}

.videoWrapperFour {
  position: relative;
  width: 100%;
  max-width: 17cm; /* Ancho máximo del contenedor del video */
  aspect-ratio: 16 / 9; /* Relación de aspecto para evitar recortes */
}

.videoThumbnailFour {
  width: 100%;
  height: auto; /* Se ajusta automáticamente para mantener la proporción */
  object-fit: contain; /* Asegura que la imagen no se recorte */
  display: block; /* Elimina cualquier comportamiento inline */
}


.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente al pasar el mouse */
}

.playButton {
  width: 68px;
  height: 48px;
  background-color: rgba(255, 0, 0, 0.9); /* Color rojo del botón */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra del botón */
}

.playIcon {
  width: 24px;
  height: 24px;
  fill: white;
}

.exclusiveOpportunitySection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem; /* Espaciado entre el texto y la imagen */
  padding: 4rem 2rem; /* Espaciado interno de la sección */
  background-color: white; /* Fondo blanco */
}

.exclusiveLeftContent {
  flex: 1; /* Toma el mismo espacio que la imagen */
  max-width: 600px; /* Limita el ancho máximo del texto */
}

.exclusiveSectionTitle {
  font-size: 2.5rem;
  font-weight: 900;
  color: #000; /* Texto en negro */
  margin-bottom: 1rem;
  font-family: 'Helvetica Now Display Extrablack', sans-serif;
}

.exclusiveDescription {
  font-size: 1.125rem;
  line-height: 1.6;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 600;
  color: #4b5563;
}

.exclusiveRightContent {
  flex: 1; /* Toma el mismo espacio que el texto */
  display: flex;
  justify-content: center;
  align-items: center;
}

.exclusiveOpportunityImage {
  max-width: 100%; /* Se ajusta al tamaño del contenedor */
  height: auto; /* Mantiene las proporciones */
}

.exclusivePuzzleSection {
  display: flex;
  align-items: center; /* Centra verticalmente imagen y texto */
  justify-content: space-between;
  gap: 2rem; /* Espaciado entre el texto y la imagen */
  padding: 4rem 2rem; /* Espaciado interno */
  background-color: #F2F2F2; /* Fondo claro */
  position: relative; /* Habilita posicionamiento relativo */
  overflow: visible; /* Permite que el brazo sobresalga completamente */
}

.leftContentHand {
  position: relative; /* Posiciona la imagen dentro del contenedor */
  flex: 1; /* Asegura que la imagen ocupe su espacio proporcional */
}

.puzzleImage {
  position: absolute; /* Permite que la imagen salga del contenedor */
  left: -4rem; /* Ajusta cuánto sobresale el brazo */
  top: 50%; /* Centra la imagen verticalmente */
  transform: translateY(-50%); /* Ajusta el centro vertical */
  max-width: none; /* Asegura que la imagen no se recorte */
  height: auto; /* Mantiene la proporción */
}

.rightContentHand {
  flex: 1; /* Asegura que el texto tome espacio proporcional */
  padding-left: 6rem; /* Crea espacio entre la imagen y el texto */
}

.sectionTitleHand {
  font-size: 2.5rem;
  font-weight: 900;
  color: #000; /* Texto en negro */
  margin-bottom: 1rem;
  text-align: left; /* Texto alineado a la izquierda dentro del lado derecho */
  font-family: 'Helvetica Now Display Extrablack', sans-serif;
}

.descriptionHand {
  font-size: 1.125rem;
  line-height: 1.6;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 600;
  color: #4b5563;
  text-align: left; /* Texto alineado a la izquierda */
}

@media (max-width: 767px) {
  .parentEducationSection {
    flex-direction: column; /* Apilar contenido verticalmente */
    padding: 1rem; /* Reducir padding general */
    text-align: center; /* Centrar el contenido */
  }

  .parentEducationSectionSec {
    flex-direction: column-reverse; /* Apilar contenido verticalmente */
    padding: 1rem; /* Reducir padding general */
    text-align: center; /* Centrar el contenido */
  }

  .textContent {
    padding: 1rem; /* Reducir padding interno */
    margin: 0 auto; /* Centrar en la pantalla */
    text-align: center; /* Centrar el texto */
  }

  .sectionTitle {
    font-size: 1.2rem; /* Reducir tamaño de fuente */
    margin-bottom: 0.5rem; /* Ajustar margen inferior */
  }

  .sectionSubtitle {
    font-size: 1.7rem; /* Reducir tamaño de fuente */
    margin-bottom: 1.5rem; /* Espaciado más grande para evitar corte */
    line-height: 1.3; /* Ajustar interlineado */
  }
  
  .descriptionThree {
    font-size: 1rem; /* Reducir tamaño de fuente */
    line-height: 1.5; /* Ajustar interlineado */
    padding: 0 1rem; /* Espaciado interno lateral */
  }

  .educationImage {
    width: 90%; /* Ocupa el 90% del ancho disponible */
    height: auto; /* Mantener proporción */
    margin: 0 auto 0 auto; /* Centrar imagen y eliminar espacio adicional */
  }

  .sectionsWrapper {
    gap: 0; /* Ajustar espacio entre secciones */
    margin-bottom: 0; /* Eliminar margen inferior que genera línea */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .gradientBackground {
    width: 13cm;
    height: 10cm;
  }

  .parentEducationSection {
    flex-direction: column; /* Apilar contenido verticalmente */
    padding: 1rem; /* Reducir padding general */
    text-align: center; /* Centrar el contenido */
  }

  .parentEducationSectionSec {
    flex-direction: column-reverse; /* Apilar contenido verticalmente */
    padding: 1rem; /* Reducir padding general */
    text-align: center; /* Centrar el contenido */
  }

  .textContent {
    padding: 1rem; /* Reducir padding interno */
    margin: 0 auto; /* Centrar en la pantalla */
    text-align: center; /* Centrar el texto */
  }

  .sectionTitle {
    font-size: 1.2rem; /* Reducir tamaño de fuente */
    margin-bottom: 0.5rem; /* Ajustar margen inferior */
  }

  .sectionSubtitle {
    font-size: 1.7rem; /* Reducir tamaño de fuente */
    margin-bottom: 1.5rem; /* Espaciado más grande para evitar corte */
    line-height: 1.3; /* Ajustar interlineado */
  }
  
  .descriptionThree {
    font-size: 1rem; /* Reducir tamaño de fuente */
    line-height: 1.5; /* Ajustar interlineado */
    padding: 0 1rem; /* Espaciado interno lateral */
  }

  .educationImage {
    width: 90%; /* Ocupa el 90% del ancho disponible */
    height: auto; /* Mantener proporción */
    margin: 0 auto 0 auto; /* Centrar imagen y eliminar espacio adicional */
  }

  .sectionsWrapper {
    gap: 0; /* Ajustar espacio entre secciones */
    margin-bottom: 0; /* Eliminar margen inferior que genera línea */
  }
}


/* FOOTER */
.footer {
  background-color: #fff; /* Fondo similar al de la imagen */
  padding: 1.5rem 2rem;
  text-align: center;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem; /* Espaciado entre secciones */
}

.logoContainer {
  margin-bottom: 0.5rem; /* Ajustamos el espacio debajo del logo */
  transform: translateX(10px); /* Mueve el logo un poco a la derecha */
  margin-left: -2.5rem;
}

.logo {
  height: 30px;
  width: auto;
}

.socialContainer {
  margin-bottom: 0.5rem;
}

.socialIcons {
  display: flex;
  justify-content: center;
  gap: 1rem; /* Espaciado entre íconos */
}

.socialLink {
  font-size: 1.8rem; /* Tamaño de los íconos */
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  padding: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.socialLink:hover {
  background-color: #fff; /* Fondo blanco al pasar el mouse */
  color: #000; /* Cambia el color del ícono */
  transform: scale(1.1); /* Aumenta el tamaño al pasar el mouse */
}

.contactContainer {
  font-size: 1rem;
  margin-top: 0.5rem; /* Espacio pequeño encima del contacto */
}

.contactTitle {
  margin-top: 0rem;
  margin-bottom: 0.2rem; /* Reduce el espacio entre "Contactos:" y el email */
  font-weight: bold;
  color: #333;
}

.email {
  color: #000;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
}

.email:hover {
  text-decoration: underline;
}

