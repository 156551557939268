/* HEADER */

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Evita el desplazamiento horizontal */
}

.family {
  background-color: white;
}

.header {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.headerWhite {
  background-color: white;
  color: black;
}

.headerBlack {
  background-color: #1B1B1B;
  color: white;
}

.headerContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  align-items: center; /* Asegura que el logo y los enlaces estén alineados verticalmente */
  gap: 1rem; /* Espaciado entre el logo y los enlaces */
}


.navLink {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  font-family: 'Helvetica Now Display', sans-serif; /* Cambia la fuente */
  font-size: 1rem; /* Tamaño de texto */
}

.navLink:hover {
  color: #22c55e;
}


.headerBlack .navLink {
  color: white;
}

.headerBlack .navLink:hover {
  color: #AE7EF2;
}

/* HEADER GENERAL */
.logo {
  height: 30px; /* Tamaño estándar */
  width: auto;
  margin-left: -4rem; /* Corre el logo más hacia la izquierda */
  margin-right: 2rem;
}

/* Menú estándar */
.nav {
  display: flex;
  gap: 1rem;
}

/* Menú hamburguesa exclusivo para mobile */
.hamburger {
  display: none; /* Oculto por defecto */
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
  position: relative; /* Añadir esta propiedad */
  left: 0px;
  transition: color 0.3s ease;
}

.headerWhite .hamburger {
  color: black; /* Cambia el color del menú a negro */
}

.headerBlack .hamburger {
  color: white; /* Cambia el color del menú a blanco */
}


.mobileMenu {
  display: none; /* Oculta por defecto */
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #1B1B1B;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 999;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.mobileMenu .navLink {
  color: white;
  margin-bottom: 1rem;
  text-decoration: none;
  font-size: 1rem;
  text-align: center;
}

.mobileMenu .navLink:hover {
  color: #22c55e;
}

.headerWhite .mobileMenu {
  background-color: white; /* Fondo blanco */
  color: black; /* Texto negro */
  border: 1px solid #ccc; /* Borde sutil para separar del fondo */
}

.headerWhite .mobileMenu .navLink {
  color: black; /* Color de los enlaces */
}

.headerWhite .mobileMenu .navLink:hover {
  color: #22c55e; /* Color de hover en familia */
}


.headerBlack .mobileMenu {
  background-color: #1B1B1B; /* Fondo negro */
  color: white; /* Texto blanco */
}

.headerBlack .mobileMenu .navLink {
  color: white; /* Color de los enlaces */
}

.headerBlack .mobileMenu .navLink:hover {
  color: #AE7EF2; /* Color de hover en campus */
}


/* Estilos específicos para mobile */
@media (max-width: 1024px) {
  .nav {
    display: none; /* Oculta el menú estándar */
  }

  .hamburger {
    display: block; /* Muestra el menú hamburguesa */
  }

  .mobileMenu {
    display: flex; /* Muestra el menú desplegable al abrir */
  }

  .headerContent {
    justify-content: space-between; /* Alinea logo y hamburguesa */
    padding: 0 1rem; /* Ajusta padding para evitar desplazamientos */
  }

  .logo {
    margin: 0; /* Elimina márgenes innecesarios */
    height: 20px;
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Evita el desplazamiento horizontal */
  }
  
  .family {
    background-color: white;
  }
  
  .header {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .headerWhite {
    background-color: white;
    color: black;
  }
  
  .headerBlack {
    background-color: #1B1B1B;
    color: white;
  }
  
  .headerContent {
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    align-items: center; /* Asegura que el logo y los enlaces estén alineados verticalmente */
    gap: 1rem; /* Espaciado entre el logo y los enlaces */
  }
  
  
  .navLink {
    color: #333;
    text-decoration: none;
    font-weight: 500;
    font-family: 'Helvetica Now Display', sans-serif; /* Cambia la fuente */
    font-size: 1rem; /* Tamaño de texto */
  }
  
  .navLink:hover {
    color: #22c55e;
  }
  
  
  .headerBlack .navLink {
    color: white;
  }
  
  .headerBlack .navLink:hover {
    color: #AE7EF2;
  }
  
  /* HEADER GENERAL */
  .logo {
    height: 30px; /* Tamaño estándar */
    width: auto;
    margin-left: -4rem; /* Corre el logo más hacia la izquierda */
    margin-right: 2rem;
  }
  
  /* Menú estándar */
  .nav {
    display: flex;
    gap: 1rem;
  }
  
  /* Menú hamburguesa exclusivo para mobile */
  .hamburger {
    display: none; /* Oculto por defecto */
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
    position: relative; /* Añadir esta propiedad */
    left: 0px;
    transition: color 0.3s ease;
  }
  
  .headerWhite .hamburger {
    color: black; /* Cambia el color del menú a negro */
  }
  
  .headerBlack .hamburger {
    color: white; /* Cambia el color del menú a blanco */
  }
  
  
  .mobileMenu {
    display: none; /* Oculta por defecto */
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #1B1B1B;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 999;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .mobileMenu .navLink {
    color: white;
    margin-bottom: 1rem;
    text-decoration: none;
    font-size: 1rem;
    text-align: center;
  }
  
  .mobileMenu .navLink:hover {
    color: #22c55e;
  }
  
  .headerWhite .mobileMenu {
    background-color: white; /* Fondo blanco */
    color: black; /* Texto negro */
    border: 1px solid #ccc; /* Borde sutil para separar del fondo */
  }
  
  .headerWhite .mobileMenu .navLink {
    color: black; /* Color de los enlaces */
  }
  
  .headerWhite .mobileMenu .navLink:hover {
    color: #22c55e; /* Color de hover en familia */
  }
  
  
  .headerBlack .mobileMenu {
    background-color: #1B1B1B; /* Fondo negro */
    color: white; /* Texto blanco */
  }
  
  .headerBlack .mobileMenu .navLink {
    color: white; /* Color de los enlaces */
  }
  
  .headerBlack .mobileMenu .navLink:hover {
    color: #AE7EF2; /* Color de hover en campus */
  }
}


