/* HERO IMAGEN INICIAL */

.campus {
  background-color: #1B1B1B; /* Fondo negro */
  color: #fff; /* Texto blanco para que sea visible en fondo negro */
  min-height: 100vh; /* Asegura que cubra toda la altura de la ventana */
}

.hero {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  color: #000;
  margin: 0; /* Elimina márgenes */
  padding: 0;

  
}

.heroImage {
  width: 100%;
  height: 65vh;
  object-fit: cover;
  display: block; /* Evita comportamientos inline */
  margin: 0; /* Elimina márgenes extra */
  padding: 0; /* Elimina cualquier padding */
  position: relative;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
  }
  
}

.heroContent {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
  }
  
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.brandLogo {
  height: 23px;
  width: auto;
}

.brandHighlight {
  color: #22c55e;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1;
}

.heroTitle {
  font-size: 4rem;
  font-weight: 900; /* O extrablack si está disponible */
  color: #fff;
  line-height: 1.1;
  margin: 0.5rem 0;
  font-family: 'Helvetica Now Display Extrablack', sans-serif;
  letter-spacing: -0.5px;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
}

.heroText {
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 400;
  color: #fff;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Helvetica Now Display', sans-serif;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

/* FIRST SECTION */
.startSection {
  background-color: #262626; /* Fondo más claro que negro */
  padding: 2rem 1rem; /* Reduce el padding superior e inferior */
  margin: 0; /* Espaciado interno */
  display: flex;
  justify-content: center;
  align-items: center;
}

.startContainer {
  max-width: 1200px;
  display: flex;
  gap: 2rem; /* Espaciado entre imagen y texto */
  align-items: center;
}

.imageContainer {
  flex: 1; /* La imagen toma el 50% del espacio */
  display: flex;
  justify-content: center;
}

.startImage {
  width: 100%;
  max-width: 500px; /* Tamaño máximo de la imagen */
  object-fit: cover; /* Asegura que la imagen no se deforme */

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
  }
}

.textContainer {
  flex: 1; /* El texto toma el 50% del espacio */
  color: white;
  text-align: left; /* Alinea el texto a la izquierda */

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
  }
}

.title {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
  font-family: 'Helvetica Now Display Extrablack', sans-serif;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 2rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 2rem;
  }
}

.subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: 'Helvetica Now Display', sans-serif;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 1rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 1rem;
  }
}

.description {
  font-size: 1rem;
  line-height: 1.6;
  font-family: 'Helvetica Now Display', sans-serif;
  font-weight: 600;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 0.7rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 0.7rem;
  }
  
}

.applyButtonEmp {
  background-color: #ff4500;
  color: white;
  font-size: 0.875rem; /* Ajuste del texto */
  padding: 0.5rem 1.2rem; /* Tamaño compacto */
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  margin-top: 1rem;
  text-decoration: none; /* Sin subrayado */
}

/* Animación al pasar el mouse */
.applyButtonEmp:hover {
  background-color: #e63e00; /* Cambia el color del fondo */
  transform: scale(1.1); /* Aumenta ligeramente el tamaño */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Agrega una sombra */
}

/* Efecto al presionar el botón */
.applyButtonEmp:active {
  transform: scale(0.95); /* Reduce ligeramente el tamaño */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduce la sombra */
}


/* Responsivo para dispositivos más pequeños */
@media (max-width: 767px) {
  .startContainer {
    gap: 1rem;
    flex-direction: column;
  }

  .startImage {
    max-width: 250px;
  }

  .textContainer {
    text-align: center;
    order: 1;
  }

  .imageContainer {
    order: 2; /* Imagen después del texto */
    margin-top: -16rem; /* Espaciado entre texto e imagen */
    margin-bottom: 2rem;
  }

  .applyButtonEmp {
    order: 3; /* Botón al final */
    margin-top: 1rem; /* Espaciado adicional */
    padding: 0.75rem 2rem;
  }

  .description {
    margin-bottom: 13.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .startContainer {
    gap: 1rem;
    flex-direction: column;
  }

  .startImage {
    max-width: 250px;
  }

  .textContainer {
    text-align: center;
    order: 1;
  }

  .imageContainer {
    order: 2; /* Imagen después del texto */
    margin-top: -16rem; /* Espaciado entre texto e imagen */
    margin-bottom: 2rem;
  }

  .applyButtonEmp {
    order: 3; /* Botón al final */
    margin-top: 1rem; /* Espaciado adicional */
    padding: 0.75rem 2rem;
  }

  .description {
    margin-bottom: 13.5rem;
  }
}

/* SECCION CON TARJETAS */
/* Nueva sección: Texto superior y tarjetas */
.newSection {
  padding: 1rem 1rem;
  background-color: #262626; /* Fondo ligeramente más claro que negro */
  color: #fff;
  text-align: center;

  
}

.subtitleSup {
  font-size: 1.2rem;
  font-weight: 700;
  color: #AE7EF2;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: -1rem;
  text-align: center;
  padding: 0;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 1rem;
  }
}

.titleSup {
  font-size: 4rem;
  font-weight: 900;
  color: #fff;
  line-height: 1;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  text-align: center;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 2rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 2rem;
  }
}

.highlight {
  color: #AE7EF2;
}

.fullWidthDescription {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center; /* Justifica el texto */
  
  
}

.fullWidthDescription p {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 1rem;
  color: #fff;

  @media (max-width: 767px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 0.8rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 119px);
    padding: 0;
    font-size: 0.8rem;
  }
}

/* Tarjetas */
.cardsContainer {
  display: flex;
  justify-content: space-between; /* Espaciado entre las tarjetas */
  gap: 2rem; /* Espaciado horizontal entre tarjetas */
  max-width: 1400px; /* Limitar el ancho total */
  margin: 0 auto; /* Centrar las tarjetas */
  margin-top: 2rem;
}

.card {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white; /* Fondo negro para la sección del texto */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra general */
  border: 2px solid transparent;
  box-shadow: 6px 6px 10px #F2A97E; /* Sombra naranja */
  max-height: 400px; /* Limita la altura */
  width: 30%
}

.cardImage {
  width: 53%;
  height: auto; /* Mantiene la proporción de la imagen */
  background-color: white; /* Fondo blanco */
  object-fit: contain; /* Ajusta la imagen completa dentro del contenedor */
  display: block; /* Corrige cualquier comportamiento inline */
  min-height: 250px; /* Ajusta la altura mínima para alinear las imágenes */
  box-sizing: border-box;
  padding-top: 1rem;
  margin: 0 auto;
  margin-top: -1rem;
}

.cardImageSec {
  width: 70%;
  height: auto; /* Mantiene la proporción de la imagen */
  background-color: white; /* Fondo blanco */
  object-fit: contain; /* Ajusta la imagen completa dentro del contenedor */
  display: block; /* Corrige cualquier comportamiento inline */
  min-height: 250px; /* Ajusta la altura mínima para alinear las imágenes */
  box-sizing: border-box;
  padding-top: 1rem;
  margin: 0 auto;
  margin-top: -1rem;
}

.cardImageThird {
  width: 100%;
  height: auto; /* Mantiene la proporción de la imagen */
  background-color: white; /* Fondo blanco */
  object-fit: contain; /* Ajusta la imagen completa dentro del contenedor */
  display: block; /* Corrige cualquier comportamiento inline */
  min-height: 250px; /* Ajusta la altura mínima para alinear las imágenes */
  box-sizing: border-box;
  padding-top: 1rem;
  margin: 0 auto;
  margin-top: -1rem;
}


.cardText {
  padding: 1rem;
  text-align: left;
  background-color: #1B1B1B;
  flex-grow: 1; /* Permite que el texto ocupe el espacio restante */
  margin-top: -1rem;
}

.cardText h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: white;
}

.cardText p {
  font-size: 0.9rem;
  line-height: 1.4;
  color: #ccc; /* Color del texto */
  
}

@media (min-width: 768px) and (max-width: 1024px) {
  .cardsContainer {
    flex-direction: column; /* Cambia a diseño en columna si es necesario */
    align-items: center; /* Centra las tarjetas */
    gap: 1.5rem; /* Reduce el espacio entre tarjetas */
    margin: 1rem auto; /* Ajusta el margen */
  }

  .card {
    width: 90%; /* Ocupa un mayor ancho en tabletas */
    max-height: none; /* Permite más flexibilidad en la altura */
    box-shadow: 4px 4px 8px rgba(242, 169, 126, 0.7); /* Ajusta la sombra */
  }

  .cardImage, .cardImageSec, .cardImageThird {
    width: 60%; /* Reduce el tamaño de las imágenes */
    min-height: 200px; /* Ajusta la altura mínima para tabletas */
    padding-top: 0.5rem; /* Reduce el espacio superior */
  }

  .cardText {
    padding: 0.8rem; /* Reduce el padding para el texto */
    text-align: center; /* Centra el texto para una mejor lectura */
    font-size: 0.95rem; /* Ajusta el tamaño del texto */
  }

  .cardText h3 {
    font-size: 1.1rem; /* Ajusta el tamaño del encabezado */
    margin-bottom: 0.3rem;
  }

  .cardText p {
    font-size: 0.85rem; /* Reduce el tamaño del párrafo */
    line-height: 1.3;
  }
}


@media (max-width: 767px) {
  .cardsContainer {
    flex-direction: column; /* Apila las tarjetas verticalmente */
    gap: 1rem;
  }

  .card {
    width: 90%; /* Ocupa casi todo el ancho de la pantalla */
    margin: 0 auto; /* Centrar tarjetas */
  }

  .cardImage {
    width: 100%; /* Imagen ajustada al ancho de la tarjeta */
    max-height: 200px; /* Limitar altura para evitar que sea demasiado grande */
  }

  .cardText h3 {
    font-size: 1.2rem; /* Reducir tamaño del título */
  }

  .cardText p {
    font-size: 0.9rem; /* Reducir tamaño del texto */
  }
}

@media (max-width: 767px) {
  .cardText {
    padding: 1rem;
    text-align: left; /* Mantiene el texto alineado a la izquierda */
    font-size: 0.85rem; /* Reduce ligeramente el tamaño del texto */
    line-height: 1.4; /* Ajusta el interlineado */
  }

  .cardText h3 {
    font-size: 1rem; /* Reduce el tamaño del título */
    margin-bottom: 0.5rem; /* Asegura espacio entre título y texto */
  }

  .cardText p {
    font-size: 0.8rem; /* Reduce el tamaño del texto de los párrafos */
    line-height: 1.3; /* Mejora el espaciado entre líneas */
  }

  .card {
    max-height: none; /* Permite que la tarjeta crezca en altura según el contenido */
  }

  .cardImage {
    max-width: 100%; /* Asegura que la imagen no se corte */
    height: auto; /* Mantiene la proporción de la imagen */
  }
}


/* SECCIONES 3 */
.sectionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.parentEducationSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  padding: 2rem 0;
  background-color: #262626;
}

.parentEducationSectionSec {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  padding: 2rem 0;
  background-color: #262626;
}

.leftContent 
.rightContent {
  flex: none;
}

.gradientBackground {
  width: 11cm;
  height: 11cm;
  padding: 1rem;
  border-radius: 0;
  background: 
  radial-gradient(circle at 80% 80%, #3C3340 30%, transparent 60%),
  radial-gradient(circle at 70% 40%, #273240 20%, transparent 60%),
  radial-gradient(circle at 30% 70%, #283740 25%, transparent 60%),
  radial-gradient(circle at 20% 20%, #403D40 30%, transparent 70%),
  radial-gradient(circle at 50% 50%, #274040 35%, transparent 70%);
  background-color: #262626;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textContent {
  color: #fff;
  text-align: left;
  padding-left: 2rem;
}

.sectionTitle {
  font-size: 1rem;
  font-weight: 100; /* O extrablack si está disponible */
  color: #fff;
  margin-bottom: -2rem; /* Reduce el margen inferior */
  margin-top: 0rem;
  font-family: 'Helvetica Now Display Extrablack', sans-serif;
}


.sectionSubtitle {
  font-size: 3rem;
  margin-bottom: -0.5rem;
  font-weight: 700;
  color: #fff;
  font-family: 'Helvetica Now Display', sans-serif;
  line-height: 1;
}

.descriptionThree {
  font-size: 1.125rem;
  line-height: 1.4;
  color: #fff;
}

.educationImage {
  width: 17cm;
  height: 11cm;
  object-fit: cover;
  border-radius: 0;
  margin-left: -0.1rem;
}

.educationImageStep {
  max-width: 17cm;
  height: 11cm;
  max-height: 400px; /* Limita la altura máxima */
  object-fit: cover; /* Asegura que la imagen se ajuste al contenedor */
  overflow: hidden; /* Oculta cualquier parte de la imagen que exceda */
  border-radius: 0px; /* Mantén los bordes redondeados si los tienes */
}

@media (max-width: 767px) {
  .parentEducationSection {
    flex-direction: column; /* Apilar contenido verticalmente */
    padding: 1rem; /* Reducir padding general */
    text-align: center; /* Centrar el contenido */
  }

  .parentEducationSectionSec {
    flex-direction: column-reverse; /* Apilar contenido verticalmente */
    padding: 1rem; /* Reducir padding general */
    text-align: center; /* Centrar el contenido */
  }

  .textContent {
    padding: 1rem; /* Reducir padding interno */
    margin: 0 auto; /* Centrar en la pantalla */
    text-align: center; /* Centrar el texto */
  }

  .sectionTitle {
    font-size: 1.2rem; /* Reducir tamaño de fuente */
    margin-bottom: 0.5rem; /* Ajustar margen inferior */
  }

  .sectionSubtitle {
    font-size: 1.8rem; /* Reducir tamaño de fuente */
    margin-bottom: 1.5rem; /* Espaciado más grande para evitar corte */
    line-height: 1.3; /* Ajustar interlineado */
  }
  
  .descriptionThree {
    font-size: 1rem; /* Reducir tamaño de fuente */
    line-height: 1.5; /* Ajustar interlineado */
    padding: 0 1rem; /* Espaciado interno lateral */
  }

  .educationImageDos {
    width: 90%; /* Ocupa el 90% del ancho disponible */
    height: auto; /* Mantener proporción */
    margin: 0 auto 0 auto; /* Centrar imagen y eliminar espacio adicional */
  }

  .sectionsWrapper {
    gap: 0; /* Ajustar espacio entre secciones */
    margin-bottom: 0; /* Eliminar margen inferior que genera línea */
  }

  .educationImageStep {
    max-width: 10cm;
    height: 5cm;
    max-height: 400px; /* Limita la altura máxima */
    object-fit: cover; /* Asegura que la imagen se ajuste al contenedor */
    overflow: hidden; /* Oculta cualquier parte de la imagen que exceda */
    border-radius: 0px; /* Mantén los bordes redondeados si los tienes */
  }
}

@media (min-width: 768px) and (max-width: 1024px)  {
  .gradientBackground {
    width: 13cm;
    height: 10cm;
  }

  .parentEducationSection {
    flex-direction: column; /* Apilar contenido verticalmente */
    padding: 1rem; /* Reducir padding general */
    text-align: center; /* Centrar el contenido */
  }

  .parentEducationSectionSec {
    flex-direction: column-reverse; /* Apilar contenido verticalmente */
    padding: 1rem; /* Reducir padding general */
    text-align: center; /* Centrar el contenido */
  }

  .textContent {
    padding: 1rem; /* Reducir padding interno */
    margin: 0 auto; /* Centrar en la pantalla */
    text-align: center; /* Centrar el texto */
  }

  .sectionTitle {
    font-size: 1.2rem; /* Reducir tamaño de fuente */
    margin-bottom: 0.5rem; /* Ajustar margen inferior */
  }

  .sectionSubtitle {
    font-size: 1.8rem; /* Reducir tamaño de fuente */
    margin-bottom: 1.5rem; /* Espaciado más grande para evitar corte */
    line-height: 1.3; /* Ajustar interlineado */
  }
  
  .descriptionThree {
    font-size: 1rem; /* Reducir tamaño de fuente */
    line-height: 1.5; /* Ajustar interlineado */
    padding: 0 1rem; /* Espaciado interno lateral */
  }

  .educationImageDos {
    width: 90%; /* Ocupa el 90% del ancho disponible */
    height: auto; /* Mantener proporción */
    margin: 0 auto 0 auto; /* Centrar imagen y eliminar espacio adicional */
  }

  .sectionsWrapper {
    gap: 0; /* Ajustar espacio entre secciones */
    margin-bottom: 0; /* Eliminar margen inferior que genera línea */
  }

  .educationImageStep {
    max-width: 15cm;
    height: 10cm;
    max-height: 400px; /* Limita la altura máxima */
    object-fit: cover; /* Asegura que la imagen se ajuste al contenedor */
    overflow: hidden; /* Oculta cualquier parte de la imagen que exceda */
    border-radius: 0px; /* Mantén los bordes redondeados si los tienes */
  }
}

.educationSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1B1B1B; /* Fondo negro */
  padding: 1rem 2rem; /* Reducir el padding vertical a 1rem */
  gap: 1rem;
  margin-top: 0; /* Ajusta según sea necesario */
  margin-bottom: 1rem; /* Para recortar el espaciado inferior */
}

.educationContent {
  flex: 1;
  color: #fff; /* Texto blanco */
  font-family: 'Helvetica Now Display', sans-serif;
  margin-left: 5rem;
}

.educationTitle {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.3;
}

.educationDescription {
  font-size: 1.125rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: #fff; /* Texto secundario */
}

.educationButton {
  background-color: #ff4500; /* Botón naranja */
  color: #fff;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s ease; /* Transición suave para todos los cambios */
  text-decoration: none; /* Asegúrate de que no haya subrayado */
}

/* Animación al pasar el mouse */
.educationButton:hover {
  background-color: #e63e00; /* Cambia el color del fondo */
  transform: scale(1.1); /* Aumenta ligeramente el tamaño */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Agrega una sombra */
}

/* Opcional: Animación de "presión" al hacer clic */
.educationButton:active {
  transform: scale(0.95); /* Reduce ligeramente el tamaño */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduce la sombra */
}


.educationImageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
}


@media (max-width: 767px) {
  .educationSection {
    flex-direction: column; /* Cambia a disposición vertical */
    align-items: center; /* Centra los elementos */
    padding: 1rem; /* Ajusta el padding */
    margin-bottom: -2rem;
    margin-top: -1rem;
  }

  .educationContent {
    margin-left: 0; /* Elimina el margen izquierdo */
    text-align: center; /* Centra el texto */
    margin-bottom: 1rem; /* Añade separación inferior */
  }

  .educationTitle {
    font-size: 2rem; /* Reduce el tamaño del título */
    line-height: 1.4;
  }

  .educationDescription {
    font-size: 1rem; /* Reduce el tamaño del texto */
    line-height: 1.4;
    margin-bottom: 16rem; /* Ajusta la separación inferior */
  }

  .educationButton {
    font-size: 1rem;
    padding: 0.75rem 2rem; /* Reduce el tamaño del botón */
    margin-top: 1rem;
    text-decoration: none;
  }

  .educationImageContainer {
    margin-top: 1rem; /* Añade espacio superior */
    width: 100%; /* Asegura que ocupe el ancho disponible */
    display: flex;
    justify-content: center; /* Centra la imagen */
  }

  .educationImage {
    max-width: 100%; /* Ajusta el tamaño de la imagen */
    height: 50%; /* Mantén la proporción */
    margin-top: -22rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .educationSection {
    flex-direction: column; /* Cambia a disposición vertical */
    align-items: center; /* Centra los elementos */
    padding: 1rem; /* Ajusta el padding */
    margin-bottom: -2rem;
    margin-top: -1rem;
  }

  .educationContent {
    margin-left: 0; /* Elimina el margen izquierdo */
    text-align: center; /* Centra el texto */
    margin-bottom: 1rem; /* Añade separación inferior */
  }

  .educationTitle {
    font-size: 2rem; /* Reduce el tamaño del título */
    line-height: 1.4;
    padding: 0;
  }

  .educationDescription {
    font-size: 1rem; /* Reduce el tamaño del texto */
    line-height: 1.4;
    margin-bottom: 29rem; /* Ajusta la separación inferior */
    padding: 0;
  }

  .educationButton {
    font-size: 1rem;
    padding: 0.75rem 2rem; /* Reduce el tamaño del botón */
    margin-top: 1rem;
    text-decoration: none;
  }

  .educationImageContainer {
    margin-top: 1rem; /* Añade espacio superior */
    width: 100%; /* Asegura que ocupe el ancho disponible */
    display: flex;
    justify-content: center; /* Centra la imagen */
  }

  .educationImage {
    max-width: 100%; /* Ajusta el tamaño de la imagen */
    height: 50%; /* Mantén la proporción */
    margin-top: -36rem;
  }
}


/* ULTIMA SECCION */
.opportunitySection {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #262626;
  gap: 3rem;
  overflow: hidden;
  
}

.leftImageContainer {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.opportunityImage {
  max-width: 130%;
  height: auto;
  display: block;
  margin-bottom: -50px;
  transform: translateY(-20px) scale(1.09) translateX(10%)
}

.imageCaption {
  font-size: 1.2rem;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.rightTextContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5rem;
  text-align: left;
  margin-bottom: 4rem;
}

.opportunityTitle {
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.opportunityDescription {
  font-size: 1.2rem;
  line-height: 1.2;
  color: white;
  margin-bottom: 1rem;
}

.applyButton {
  background-color: #ff4500;
  color: white;
  font-size: 1rem; /* Tamaño del texto */
  padding: 0.5rem 1.2rem; /* Ajusta el relleno para el tamaño */
  border: none;
  border-radius: 2rem; /* Botón cilíndrico */
  cursor: pointer;
  transition: all 0.3s ease; /* Suaviza todos los cambios */
  display: block;
  margin: 0 1; /* Centrar el botón */
  width: 100px; /* Ancho fijo del botón */
  text-align: center;
  margin-top: 1.5rem;
  text-decoration: none;
}

/* Animación al pasar el mouse */
.applyButton:hover {
  background-color: #e63e00; /* Cambia el color del fondo */
  transform: scale(1.1); /* Aumenta ligeramente el tamaño */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Agrega una sombra */
}

/* Efecto al presionar el botón */
.applyButton:active {
  transform: scale(0.95); /* Reduce ligeramente el tamaño */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduce la sombra */
}


@media (max-width: 767px) {
  .opportunitySection {
    flex-direction: column-reverse; /* Apila el contenido verticalmente */
    align-items: center; /* Centra los elementos */
    padding: 1rem; /* Ajusta el padding */
    gap: 1rem;
    margin-bottom: -1rem;
  }

  .leftImageContainer {
    flex: none;
    display: flex;
    justify-content: center; /* Centra la imagen */
    margin-bottom: 1rem; /* Espaciado inferior */
  }

  .opportunityImage {
    max-width: 90%; /* Ajusta el ancho de la imagen */
    transform: none; /* Elimina las transformaciones */
    margin: 0 auto; /* Centra la imagen */
    margin-top: -25rem;
  }

  .rightTextContainer {
    flex: none;
    text-align: center; /* Centra el texto */
    margin: 0; /* Elimina márgenes laterales */
  }

  .opportunityTitle {
    font-size: 2rem; /* Reduce el tamaño del título */
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  .opportunityDescription {
    font-size: 1rem; /* Reduce el tamaño del texto */
    line-height: 1.5;
    margin-bottom: 15rem;
  }

  .applyButton {
    width: auto; /* Elimina el ancho fijo del botón */
    padding: 0.75rem 2rem; /* Ajusta el relleno */
    margin: 1rem auto 0; /* Centra el botón */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .opportunitySection {
    flex-direction: column-reverse; /* Apila el contenido verticalmente */
    align-items: center; /* Centra los elementos */
    padding: 1rem; /* Ajusta el padding */
    gap: 1rem;
    margin-bottom: -1rem;
  }

  .leftImageContainer {
    flex: none;
    display: flex;
    justify-content: center; /* Centra la imagen */
    margin-bottom: 1rem; /* Espaciado inferior */
  }

  .opportunityImage {
    max-width: 90%; /* Ajusta el ancho de la imagen */
    transform: none; /* Elimina las transformaciones */
    margin: 0 auto; /* Centra la imagen */
    margin-top: -39rem;
  }

  .rightTextContainer {
    flex: none;
    text-align: center; /* Centra el texto */
    margin: 0; /* Elimina márgenes laterales */
  }

  .opportunityTitle {
    font-size: 2rem; /* Reduce el tamaño del título */
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  .opportunityDescription {
    font-size: 1rem; /* Reduce el tamaño del texto */
    line-height: 1.5;
    margin-bottom: 28rem;
    padding: 0;
  }

  .applyButton {
    width: auto; /* Elimina el ancho fijo del botón */
    padding: 0.75rem 2rem; /* Ajusta el relleno */
    margin: 1rem auto 0; /* Centra el botón */
  }
}



/* FOOTER */
.footer {
  background-color: #1B1B1B; /* Fondo similar al de la imagen */
  padding: 1.5rem 2rem;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem; /* Espaciado entre secciones */
}

.logoContainer {
  margin-bottom: 0.5rem; /* Ajustamos el espacio debajo del logo */
  transform: translateX(10px); /* Mueve el logo un poco a la derecha */
  margin-left: -2.5rem;
}

.logo {
  height: 30px;
  width: auto;
}

.socialContainer {
  margin-bottom: 0.5rem;
}

.socialIcons {
  display: flex;
  justify-content: center;
  gap: 1rem; /* Espaciado entre íconos */
}

.socialLink {
  font-size: 1.8rem; /* Tamaño de los íconos */
  color: #1B1B1B;
  background-color: #fff;
  border-radius: 50%;
  padding: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.socialLink:hover {
  background-color: #1B1B1B; /* Fondo blanco al pasar el mouse */
  color: #fff; /* Cambia el color del ícono */
  transform: scale(1.1); /* Aumenta el tamaño al pasar el mouse */
}

.contactContainer {
  font-size: 1rem;
  margin-top: 0.5rem; /* Espacio pequeño encima del contacto */
}

.contactTitle {
  margin-top: 0rem;
  margin-bottom: 0.2rem; /* Reduce el espacio entre "Contactos:" y el email */
  font-weight: bold;
  color: #fff;
}

.email {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
}

.email:hover {
  text-decoration: underline;
}

